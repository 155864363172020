.mySwiperslider {
  width: 100%;
  height: 110vh !important;
}
@media (max-width: 768px) {
  .mySwiperslider {
    width: 100%;
    height: auto !important;
  }
}
.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  /* display: flex;
  justify-content: center;
  align-items: start; */
}

.slider-home {
  display: flex;
  justify-content: center !important;
  align-items: start !important;
  flex-direction: column;
  width: 100%;
  object-fit: contain;
  background-size: cover;
  height: 100vh;
  /* background-position-y: top !important; */
  background-repeat: no-repeat;
  /* background-position: top; */


}

.slider-home1 {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-size: cover;
  background-position-y: -100px;
  background-repeat: no-repeat;

}

.swiper-button-next {
  color: black !important;
}

.swiper-button-prev {
  color: black !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {

  font-size: 40px !important;
}

@media (min-width: 320px) and (max-width: 425px) {

  .swiper-button-prev:after,
  .swiper-button-next:after {

    font-size: 16px !important;
  }
}