.swiper {
  width: 100%;
  min-height: 50vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-size: cover;
  background-position-y: -100px;
  background-repeat: no-repeat;
}

.slider-home-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 110vh;
  object-fit: cover;
  background-size: cover;
  background-position-y: 0;
  background-repeat: no-repeat;

}

@media (max-width: 768px) {
  .slider-home-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height:50vh !important;
    object-fit: cover;
    background-size: cover;
    background-position-y: 0;
    background-repeat: no-repeat;
  
  }

  .slider-home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 50vh !important; 
    object-fit: cover;
    background-size: cover;
    background-position-y: -100px;
    background-repeat: no-repeat;
  }
  
}